<template>
  <div>
    <div class="header-wapper">
      <a-page-header
        :title="entity.name"
        :sub-title="`ID：${entity.id}`"
        @back="() => $router.go(-1)"
      />
    </div>
    <div class="profile-wrapper">
      <a-descriptions :title="`ID：${entity.id}`" layout="vertical">
        <a-descriptions-item label="Logo">
          <img
            v-if="entity.properties"
            :src="entity.properties.logo"
            alt=""
            srcset=""
            width="100"
          />
        </a-descriptions-item>
        <a-descriptions-item label="名称">
          {{ entity.name }}
        </a-descriptions-item>
        <a-descriptions-item label="描述">
          {{ entity.description }}
        </a-descriptions-item>
        <a-descriptions-item label="展位号">
          {{ entity.booth }}
        </a-descriptions-item>
        <a-descriptions-item label="点赞">
          {{ entity.favoriteNumber }}
        </a-descriptions-item>
        <a-descriptions-item label="收藏">
          {{ entity.collectNumber }}
        </a-descriptions-item>
        <a-descriptions-item label="浏览">
          {{ entity.browseNumber }}
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <a-tabs v-model="activeKey" @change="handleReset">
      <a-tab-pane key="exhibit" tab="展品">
        <exhibit-table
          :loading.sync="loading"
          :filters="filters"
          :sorter="[
            { field: 'createTime', order: 'DESC' },
            { field: 'sequence', order: 'DESC' },
          ]"
        ></exhibit-table
      ></a-tab-pane>
      <a-tab-pane key="message" tab="消息">
        <message-table
          :loading.sync="loading"
          :filters="filters"
          :sorter="[
            { field: 'createTime', order: 'DESC' },
            { field: 'sequence', order: 'DESC' },
          ]"
        ></message-table
      ></a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import ExhibitorService from "@/services/exhibitor";
import ExhibitTable from "@/views/exhibit/table";
import MessageTable from "@/views/message/table";

const defaultFilters = {};

export default {
  components: { ExhibitTable, MessageTable },
  data() {
    return {
      loading: false,
      activeKey: "exhibit",
      entity: { id: this.$route.params.id },
      filters: JSON.parse(JSON.stringify(defaultFilters)),
    };
  },
  methods: {
    async getExhibitor() {
      const result = await ExhibitorService.getEntity(this.entity);

      this.entity = result;
    },
    handleReset() {
      this.filters = JSON.parse(JSON.stringify(defaultFilters));
    },
  },
  async mounted() {
    defaultFilters.exhibitor = { id: this.$route.params.id };

    await this.getExhibitor();

    this.handleReset();
  },
};
</script>

<style lang="less" scoped>
@import "../../../index.less";
</style>
