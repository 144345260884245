<template>
  <div class="pane-wrapper">
    <div class="table-wrapper">
      <y-table
        :columns="columns"
        :loading="loading"
        :service="service"
        :entity="entity"
        :sorter="sorter"
        :filters="privateFilters"
        :addable="false"
        @add="handleClickAdd"
      >
        <template slot="action" slot-scope="action">
          <!-- <a-button type="link" @click="handleEditClick(action.record)"
            >编辑</a-button
          > -->
          <a-popconfirm
            title="是否确认删除？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleDeleteClick(action.record)"
          >
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </template>
      </y-table>
    </div>
    <editor
      :visible.sync="visible"
      :target="target"
      @close="handleEditorClose"
    ></editor>
  </div>
</template>

<script>
import MessageService from "@/services/message";
import Editor from "../editor";
const defaultFilters = {
  type: "SUPPLY",
};
const defaultEntity = {
  sender: {},
  properties: {},
};
export default {
  components: { Editor },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
    },
    sorter: {
      type: Array,
    },
    entity: {
      type: Object,
      default: () => {
        return JSON.parse(JSON.stringify(defaultEntity));
      },
    },
  },
  data() {
    return {
      service: MessageService,
      visible: false,
      visibleSortModal: false,
      privateFilters: JSON.parse(JSON.stringify(defaultFilters)),

      target: {},
      columns: [
        {
          dataIndex: "content",
          title: "内容",
          sorter: true,
          scopedSlots: { customRender: "text" },
        },
        {
          dataIndex: "type",
          title: "类型",
          customRender: (text) => {
            return <y-tag type={"MessageType"} value={text}></y-tag>;
          },
        },
        {
          dataIndex: "sender",
          title: "发送者",
          customRender: (text) => {
            return (
              <a>
                {text.name || text.accounts.length > 0
                  ? text.accounts[0].name
                  : ""}
              </a>
            );
          },
        },
        {
          dataIndex: "sender",
          title: "姓名",
          customRender: (text) => {
            return <y-tag value={text.mamber ? text.mamber.name : ""}></y-tag>;
          },
        },
        {
          dataIndex: "sender",
          title: "手机号",
          customRender: (text) => {
            return (
              <y-tag
                value={
                  text.mamber && text.mamber.phone
                    ? text.mamber.phone.number
                    : ""
                }
              ></y-tag>
            );
          },
        },
        {
          dataIndex: "receiver",
          title: "接收者",
          customRender: (text, record) => {
            if (record.type == "EXHIBIT" || record.type == "EXHIBITOR") {
              return (
                <router-link to={`/exhibitor/${record.exhibitor.id}`}>
                  {record.exhibitor.name || ""}
                </router-link>
              );
            } else {
              return (
                <a>
                  {text.name || text.accounts.length > 0
                    ? text.accounts[0].name
                    : ""}
                </a>
              );
            }
          },
        },
        {
          dataIndex: "exhibit",
          title: "关联展品",
          customRender: (text) => {
            return text && text.name ? (
              <router-link to={text ? `/exhibit/${text.id}` : null}>
                {text.name}
              </router-link>
            ) : (
              <span>/</span>
            );
          },
        },
        // {
        //   dataIndex: "sequence",
        //   title: "权重",
        //   scopedSlots: { customRender: "sequence" },
        // },
        {
          dataIndex: "creater",
          title: "创建人",
        },
        {
          dataIndex: "createTime",
          title: "创建时间",
          scopedSlots: { customRender: "dateTime" },
        },
        {
          dataIndex: "updater",
          title: "更新人",
        },
        {
          dataIndex: "updateTime",
          title: "更新时间",
          scopedSlots: { customRender: "dateTime" },
        },
        // {
        //   dataIndex: "enabled",
        //   title: "状态",
        //   scopedSlots: { customRender: "enabled" },
        // },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  watch: {
    filters: {
      handler: async function (val) {
        this.privateFilters = val;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleClickAdd() {
      this.target = {};
      this.visible = true;
    },
    handleEditClick(record) {
      this.target = record;
      this.visible = true;
    },
    handleEditorClose() {
      this.target = JSON.parse(JSON.stringify(defaultEntity));
      this.privateFilters = JSON.parse(JSON.stringify( this.filters));
    },
    async handleDeleteClick(record) {
      try {
        this.loading = true;
        await this.service.deleteEntity(record);
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.privateFilters = JSON.parse(JSON.stringify( this.filters));
        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>