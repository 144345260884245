var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"header-wapper"},[_c('a-page-header',{attrs:{"title":_vm.entity.name,"sub-title":`ID：${_vm.entity.id}`},on:{"back":() => _vm.$router.go(-1)}})],1),_c('div',{staticClass:"profile-wrapper"},[_c('a-descriptions',{attrs:{"title":`ID：${_vm.entity.id}`,"layout":"vertical"}},[_c('a-descriptions-item',{attrs:{"label":"Logo"}},[(_vm.entity.properties)?_c('img',{attrs:{"src":_vm.entity.properties.logo,"alt":"","srcset":"","width":"100"}}):_vm._e()]),_c('a-descriptions-item',{attrs:{"label":"名称"}},[_vm._v(" "+_vm._s(_vm.entity.name)+" ")]),_c('a-descriptions-item',{attrs:{"label":"描述"}},[_vm._v(" "+_vm._s(_vm.entity.description)+" ")]),_c('a-descriptions-item',{attrs:{"label":"展位号"}},[_vm._v(" "+_vm._s(_vm.entity.booth)+" ")]),_c('a-descriptions-item',{attrs:{"label":"点赞"}},[_vm._v(" "+_vm._s(_vm.entity.favoriteNumber)+" ")]),_c('a-descriptions-item',{attrs:{"label":"收藏"}},[_vm._v(" "+_vm._s(_vm.entity.collectNumber)+" ")]),_c('a-descriptions-item',{attrs:{"label":"浏览"}},[_vm._v(" "+_vm._s(_vm.entity.browseNumber)+" ")])],1)],1),_c('a-tabs',{on:{"change":_vm.handleReset},model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},[_c('a-tab-pane',{key:"exhibit",attrs:{"tab":"展品"}},[_c('exhibit-table',{attrs:{"loading":_vm.loading,"filters":_vm.filters,"sorter":[
          { field: 'createTime', order: 'DESC' },
          { field: 'sequence', order: 'DESC' },
        ]},on:{"update:loading":function($event){_vm.loading=$event}}})],1),_c('a-tab-pane',{key:"message",attrs:{"tab":"消息"}},[_c('message-table',{attrs:{"loading":_vm.loading,"filters":_vm.filters,"sorter":[
          { field: 'createTime', order: 'DESC' },
          { field: 'sequence', order: 'DESC' },
        ]},on:{"update:loading":function($event){_vm.loading=$event}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }